const leftPad = (number: number) => {
    return number < 10 ? '0' + number : number.toString();
};

const formatDateToYyyyMMdd = (date: Date | null) => {
    if (!date) return '';
    const month = leftPad(date.getMonth() + 1);
    const dayOfMonth = leftPad(date.getDate());
    return `${date.getFullYear()}-${month}-${dayOfMonth}`;
};

const formatDateToYyyyMMddhhmmss = (date: Date | null) => {
    if (!date) return '';
    const month = leftPad(date.getMonth() + 1);
    const dayOfMonth = leftPad(date.getDate());
    return `${date.getFullYear()}-${month}-${dayOfMonth} 00:00:00`;
};

const getLastDayOfMonth = (date: Date): string => {
    const dayInMonths = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    let day = dayInMonths[date.getMonth()];
    if (date.getMonth() === 1 && date.getFullYear() % 4 === 0) {
        day++;
    }
    return `${date.getFullYear()}-${leftPad(date.getMonth() + 1)}-${day}`;
};

const getDaysBetweenDates = (from: Date, to: Date): Date[] => {
    const days: Date[] = [];

    for (let day = new Date(from); day.getTime() <= to.getTime(); day.setDate(day.getDate() + 1)) {
        days.push(new Date(day));
    }
    return days;
};

export { leftPad, formatDateToYyyyMMdd, formatDateToYyyyMMddhhmmss, getLastDayOfMonth, getDaysBetweenDates };
